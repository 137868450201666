@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .btn {
    @apply px-16px py-8px rounded-4px min-w-widthButton text-12px;
  }
  .btn-normal {
    @apply btn bg-lightSurfaceSecondary dark:bg-darkSurfaceSecondary;
  }
  .btn-primary {
    @apply btn bg-accent text-white font-bold;
  }
  .btn:hover {
    @apply shadow;
  }
  .link {
    @apply underline text-lightLink dark:text-darkLink
  }
}

html {
  font-family: sans-serif;
}

svg {
  fill: currentColor;
}
